import React from "react"
import Log from "./../../../templates/log.js"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import { Icon, Table, Caption, FirstP, Emoji } from "./../../../components/helpers.js"
import { IoIosFlash, IoIosVolumeLow, IoMdBluetooth, IoMdRepeat } from "react-icons/io";

export default ({ data, pageContext }) => {

    const content = <>
        
        <FirstP>Even though my prototype was working quite well, there are still some issues. One of them is that the Bluetooth module gives audible pops on pausing and restarting playback. Another one is that I can't communicate to the Arduino if the music is paused or not and therefore can't put this on the head unit display. Partly for these reasons, and partly to learn, I started on my own PCB.</FirstP>
        
        <p>
            If you read the previous post you know that my initial prototype had two main components. An Arduino to communicate with the head unit and a Bluetooth
            module to stream the music from my phone. Instead of making a PCB with a similar setup I decided to go another route and try to use an ESP32 chip.
            This popular and well-loved MCU has integrated Bluetooth, and could therefore be used for all the tasks of my board in one centralized place.
        </p>

        <h3>PCB Components</h3>

        <p>In the table below you will find a high level overview of the selected components and what they are used for.</p>

        <Table><tbody>
            <tr><th>Component</th><th>Use</th></tr>
            <tr><td><Icon><IoMdBluetooth /></Icon> ESP32</td><td>The ESP32 will handle the Bluetooth connection and output the streaming audio. Furthermore it will also be running the CD changer emulator logic.</td></tr>
            <tr><td><Icon><IoMdRepeat /></Icon> BSS138</td><td>Because the ESP32 has 3.3V logic levels, BSS138 MOSFETs are used to do level shifting between 3.3V and the 5V logic signals required by the head unit.</td></tr>
            <tr><td><Icon><IoIosVolumeLow /></Icon> PCM1789</td><td>The integrated digital to analog converter (DAC) of the ESP32 is limited in quality. Therefore a high quality audio DAC is added with differential outputs.</td></tr>
            <tr><td><Icon><IoIosFlash /></Icon> LM2940</td><td>The LM2940 is used for conversion from 12V to 5V to supply the board. This is an automotive spec linear regulator with all sorts of internal protections.</td></tr>
            <tr><td><Icon><IoIosFlash /></Icon> LM1117</td><td>The LM1117 is used for conversion from 5V to 3.3V to supply the ESP32 as well as the logic side of the PCM1789 DAC.</td></tr>
            <tr><td style={{ whiteSpace: 'nowrap' }}><Icon><IoIosFlash /></Icon> R1SX-0505</td><td>This isolated DC/DC converter provides the 5V for the audio side of the DAC to prevent noise as much as possible.</td></tr>             
        </tbody></Table>

        <h3>PCB Assembly</h3>
        
        <p>With the components above I started to design a PCB. Other features of the PCB are an in-PCB antenna for Bluetooth and a separate ground plane under the audio part of the board.</p>

        <Img fadeIn={false} fluid={data.img1.childImageSharp.fluid} alt="PCB Assembly" />
        <Caption>Assembly process</Caption>

        <Img fadeIn={false} fluid={data.img2.childImageSharp.fluid} alt="Final result" />
        <Caption>The final board</Caption>
        
        <p>When I assembled the first board I managed to test both the audio and CD changer emulator functionality, albeit very brief. After that I touched the wrong wire to the wrong pin and shorted my board <Emoji e="😥" />.
           My second and third attempt of assembling this board were unsuccessful which led to a lot of frustration. The reason for this is probably the ESP32 Pico. I really liked using this component because it
           is a lot smaller than the more common ESP32 modules, but because of this I have difficulty with the assembly.
        </p>

        <h3>QFN Package</h3>

        <p>
            The ESP32 Pico has a QFN package, which means that there are no external pins, but instead there are pads at the bottom of the IC, also the pitch between the pins is quite small.
            I am sure that other makers use QFN packages all the time, and do so with success. However I have don't have the right skills nor the right tools to solder this small QFN package reliably.
         </p>

         <p>
            Looking at my PCB design, one improvement I could make is to make the pads around the ESP32 longer to make it easier to drag solder. However the main problem I have is 
            that I don't know how to verify that every IC pad is actually connected correctly to the PCB, since these are hidden under the IC. 
            Furthermore I could make mistakes like adding too much solder paste for the ground pad, which then lifts the QFN package of the board so that the other pins make no contact at all.
             Because of these reasons I decided to give up, and move away from the ESP32 Pico to a normal ESP32 module in my next design <Emoji e="😊" />.
        </p>

    </>;

    return (<Log pageContext={pageContext}>{content}</Log>);
}

export const query = graphql`
{
    img1: file(relativePath: { eq: "assembly.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img2: file(relativePath: { eq: "pcb.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`
